import React, { useEffect } from "react";
import "./Code.scss"
import Typist from "react-typist";

export const Code = () => {
	return (
		<div id="code">
			{/*<Typist cursor={{element: "█", blink: true}} avgTypingDelay={30}>*/}
			{/*</Typist>*/}
		</div>
	);
}
